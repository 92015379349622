import constants from 'appConstants';

const calculateTotalAmount = (items = [], property) => items.reduce((sum, entity) => sum + (entity[property] || 0), 0);

const calculatePlacedHives = ranch => {
    const dropHives = ranch.locations.reduce((acc, location) => {
        acc += calculateTotalAmount(location.standardHives, 'hivesAmount');
        acc += calculateTotalAmount(location.bhomes, 'hivesAmount');
        return acc;
    }, 0);

    const unverifiedBhomeHives =
        calculateTotalAmount(ranch.unverifiedPlacements, 'bhomesAmount') * constants.DEFAULT_BHOME_HIVES_AMOUNT;
    const unverifiedStandardHives = calculateTotalAmount(ranch.unverifiedPlacements, 'standardHivesAmount');

    return dropHives + unverifiedBhomeHives + unverifiedStandardHives;
};

export const calculateBhomes = ranch => {
    const dropBhomes = calculateTotalAmount(
        ranch.locations.map(location => ({
            bhomeCount: location.bhomes?.length || 0,
        })),
        'bhomeCount'
    );

    const unverifiedBhomes = calculateTotalAmount(ranch.unverifiedPlacements, 'bhomesAmount') ?? 0;

    return dropBhomes + unverifiedBhomes;
};

export const calculatePlacedStandardHives = ranch => {
    const dropStandardHives = ranch.locations.reduce((acc, location) => {
        acc += calculateTotalAmount(location.standardHives, 'hivesAmount');
        return acc;
    }, 0);
    const unverifiedStandardHives = calculateTotalAmount(ranch.unverifiedPlacements, 'standardHivesAmount');

    return dropStandardHives + unverifiedStandardHives;
};

export const populateRanchDataModel = ranch => ({
    ...ranch,
    totalAssignedHives: calculateTotalAmount(ranch.orders, 'hivesAmount'),
    totalPlannedHives: calculateTotalAmount(ranch.locations, 'hivesAmount'),
    totalPlacedHives: calculatePlacedHives(ranch),
    totalPlacedStandardHives: calculatePlacedStandardHives(ranch),
    totalBhomes: calculateBhomes(ranch),
});

export const populateRanchesDataModel = ranches => ranches.map(populateRanchDataModel);

export const generateQueryParams = (params = {}) => {
    const parts = Object.entries(params).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            return [...acc, ...value.map(val => `${key}=${val}`)];
        } else {
            return [...acc, `${key}=${value}`];
        }
    }, []);

    return parts.join('&');
};

export const formatNumber = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getTotalPerformances = ({ ranches, allBeekeepers }) => {
    const acc = { available: 0, contracted: 0, assigned: 0, planned: 0, placed: 0 };

    acc.available = allBeekeepers.reduce((sum, beekeeper) => sum + Number(beekeeper.data?.hivesAmount ?? 0), 0);

    ranches.forEach(ranch => {
        acc.contracted += ranch.requiredHivesAmount || 0;
        acc.assigned += ranch.totalAssignedHives || 0;
        acc.planned += ranch.totalPlannedHives || 0;
        acc.placed += ranch.totalPlacedHives || 0;
    });

    return acc;
};
